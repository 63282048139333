<template>
  <v-container fluid>
    <page-header title='Formularios de avaliação do curso'></page-header>
      <v-card style="border-radius:12px" flat outlined>
        <v-card-title primary-title class='primary white--text py-1'>
          <v-row class="px-3 py-4 pb-5" justify="space-between">
            <v-col cols='12' sm='8' class="pa-0">
              <div class="text-subtitle-2">Curso</div>
              <div style="word-break: break-word">{{course_name}}</div>
            </v-col>
            <v-col cols='12' sm='3' align="right">
              <v-row class="pt-4">
                <BtnCreateDataExport 
                  class="mx-1 mt-n1 pb-3"
                  api_model="DoneForm"
                  action="DoneForms"
                  :filters="{course_id: $route.params.id, exec_time: 'rating'}"
                  :file_name="'Avaliação - ' + course_name.substr(0,30)"
                  ></BtnCreateDataExport>
              
                <v-btn color="success" small rounded v-if="['done'].includes(course.status)" 
                :class="[$vuetify.breakpoint.mdAndUp ? '' : 'ml-3']"
                  :to="({name: 'StudentRating', params: {id: course.id}})"
                >
                  Ver alunos
                </v-btn>
                <v-btn color="success" :class="['ml-3', $vuetify.breakpoint.smAndDown ? 'mt-3' : '']" small rounded v-if="['done'].includes(course.status)" 
                  :to="({name: 'CourseRatingsDashboard', params: {id: course.id}})"
                >
                  Ver gráficos
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <card-loading-transparent v-if="loading"></card-loading-transparent>
        <v-card-text class="px-0 pb-3" v-show="!loading">
          <v-data-table :headers="headers" :items="done_forms" sort-by="calories">
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn small text color="primary" @click="doneFormDialog(item)" rounded outlined>
                <v-icon left>mdi-clipboard-text</v-icon>VER
              </v-btn>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ $dayjs(item.created_at).format('LLL') }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <dialog-rating-done-form v-if="dialog" :dialog="dialog" @closeDialog="handleCloseDialog" :selected_done_form="selected_done_form"/>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'CourseRatingsList',
  components: {
    DialogRatingDoneForm: () => import ('./ratings_components/DialogRatingDoneForm.vue'),
    BtnCreateDataExport: () => import('@/components/data_export/BtnCreateDataExport.vue'),
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: '#', align: 'start', sortable: false, value: 'id'},
      { text: 'Data', align: 'start', sortable: false, value: 'created_at'},
      //{ text: 'Status', value: 'status', sortable: false },
      { text: 'Avaliações', value: 'actions', sortable: false },
    ],
    loading: false,
    done_forms: [],
    selected_done_form: {},
    course: {},
    filters: {
      course_id: 25
    }
  }),
  methods: {
    handleCloseDialog(){
      this.dialog = false
      this.selected_done_form = {}
    },
    getInfos(){
      this.loading = true
      this.getRatingsForms()
      this.getCourseInfo()
      setTimeout(() => {
        this.loading = false
      }, 500);

    },
    getCourseInfo(){
      Api.Course.show(this.$route.params.id).then(r=>r.data).then(d=> {
        let {id, name, status} = d
        this.course = {id, name, status}
      })
    },
    getRatingsForms(){
      Api.DoneForm.index({course_id: this.$route.params.id, exec_time: 'rating'}).then(r=>r.data).then(d=> this.done_forms = d)
    },
    doneFormDialog(item){
      this.selected_done_form = item
      this.dialog = true
    },

    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    course_name(){
      return this.course.name || ''
    },
    ...mapState({
      // data: state=> state.Module.state_var
    }),
    
  },

  mounted () {
    this.getInfos();
  },
}
</script>

<style scoped>

</style>